<template>
  <div class="location">
    <a class="back" @click="goTo('/home')">
      <font-awesome-icon
        icon="fa-solid fa-chevron-left"
        size="lg"
      ></font-awesome-icon>
    </a>
    <!-- 地圖 -->
    <section class="cover">
      <!-- ********** 地圖放這裡            ********** -->
      <!-- ********** width+height設成100% ********** -->
      <div id="map" :style="{ width: '100%', height: '100%' }"></div>
    </section>
    <!-- Main -->
    <section class="main">
      <!-- 分店 -->
      <section
        :ref="`store${store.id}`"
        class="item"
        v-for="(store, index) in stores"
        :key="'store_' + index"
      >
        <a class="top" @click="drop(store)">
          <span class="title">{{ store.name }}</span>
          <font-awesome-icon icon="fa-solid fa-caret-down"></font-awesome-icon>
        </a>
        <div>
          <span>{{ store.address }}</span>
          <!-- ********** 點選複製按鈕後出現1秒 ********** -->
          <span
            :ref="`addressCopied${store.id}`"
            class="copy"
            style="display: none"
            >已複製</span
          >
          <a
            @click.prevent="
              copy(`address${store.id}`, `addressCopied${store.id}`)
            "
            ><font-awesome-icon icon="fa-solid fa-copy"></font-awesome-icon
          ></a>
          <input
            :ref="`address${store.id}`"
            type="text"
            :value="store.address"
            style="display: none"
          />
          <a
            :href="`https://www.google.com/maps/search/?api=1&query_place_id=${store.storePlaceId}&query=${store.address}`"
            ><font-awesome-icon icon="fa-solid fa-map"></font-awesome-icon>
          </a>
        </div>
        <div>
          <span>{{ store.phone }}</span>
          <!-- ********** 點選複製按鈕後出現1秒 ********** -->
          <span
            :ref="`phoneCopied${store.id}`"
            class="copy"
            style="display: none"
            >已複製</span
          >
          <a @click.prevent="copy(`phone${store.id}`, `phoneCopied${store.id}`)"
            ><font-awesome-icon icon="fa-solid fa-copy"></font-awesome-icon
          ></a>
          <input
            :ref="`phone${store.id}`"
            type="text"
            :value="store.phone"
            style="display: none"
          />
          <a :href="`tel:${store.phone}`">
            <font-awesome-icon icon="fa-solid fa-phone"></font-awesome-icon>
          </a>
        </div>
        <fragment v-if="store.openingHours">
          <div
            class="time"
            v-for="weekday in parseOpeningHours(openingHours.weekday_text)"
            :key="weekday.weekday"
          >
            <span>{{ weekday.weekday }}</span>
            <span class="right">{{ weekday.openingHours }}</span>
          </div>
        </fragment>
        <div class="map">
          <div
            :id="`map${store.id}`"
            :style="{ width: '100%', height: '100%' }"
          ></div>
        </div>
      </section>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'Stores',
  components: {},
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      stores: [],
      displayStores: [],
      isLoading: false,
      portalKey: null,
    };
  },
  async mounted() {
    this.portalKey = this.$route.query.portal_key;
    if (!this.portalKey) {
      this.goTo('/error');
      return;
    }
    await this.getStores();
    this.drawCoverMap();
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
  },
  methods: {
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    dummyStores() {
      let result = [
        {
          id: 102,
          name: '米哥烘焙坊 - 民生門市',
          address: '台北市松山區民生東路五段184之2號1樓',
          phone: '0987654321',
          banId: '85111267',
          storePlaceId: 'ChIJ_a7SIGWrQjQRGup3Joe1ehg',
          geometry: '{"location":{"lat":25.058615,"lng":121.366181}}',
          categoryName: '餐飲',
          categoryId: 1,
          isQcEnabled: 1,
          openingHours: null,
          images: [
            'https://storage.googleapis.com/qc_staging_products/library/9920/web-DR9XO5Je0',
          ],
          googleTypes: null,
          isDdpayEnabled: 1,
        },
        {
          id: 103,
          name: '米哥烘焙坊 - 景美門市',
          address: '台北市文山區木新路三段327號一樓',
          phone: '0987654321',
          banId: '85111267',
          storePlaceId: 'ChIJLUbzHeEBaDQRbPCDOiqx5H8',
          geometry:
            '{"location":{"lat":24.981993018166047,"lng":121.5557701851877}}',
          categoryName: '餐飲',
          categoryId: 1,
          isQcEnabled: null,
          openingHours: null,
          images: null,
          googleTypes: null,
          isDdpayEnabled: null,
        },
      ];
      return new Promise(resolve => {
        resolve({ data: result });
      });
    },
    getStores() {
      let config = {
        url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/portals/${this.portalKey}/stores`,
        methods: 'GET',
      };
      //   return this.dummyStores(config)
      return this.$http(config)
        .then(res => {
          console.log('getStoresResData:', res.data);
          this.stores = res.data;
        })
        .catch(function (err) {
          console.log('getStores err:', err);
        });
    },
    drawCoverMap() {
      const map = new google.maps.Map(document.getElementById('map'), {
        // center: { lat: 24.9742352, lng: 121.0703047 },
        zoom: 10,
        mapTypeId: 'roadmap',
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      });

      this.stores.forEach((store, storeIndex) => {
        const service = new google.maps.places.PlacesService(map);
        const request = {
          placeId: store.storePlaceId,
          fields: ['name', 'place_id', 'geometry', 'address_component'],
        };
        service.getDetails(request, place => {
          const marker = new google.maps.Marker({
            position: JSON.parse(JSON.stringify(place.geometry.location)),
            map: map,
          });

          marker.addListener('click', () => {
            const storeEl = this.$refs[`store${store.id}`][0];
            storeEl.scrollIntoView({ behavior: 'smooth' });
            document.querySelectorAll('.dropdown')?.forEach(el => {
              el.classList.remove('dropdown');
              el.classList.add('dropup');
            });
            this.drop(store);
          });

          if (storeIndex === 0) {
            this.setMapCenter(
              map,
              JSON.parse(JSON.stringify(place.geometry.location))
            );
          }
        });
      });
    },
    drawMap(storeInfo) {
      const map = new google.maps.Map(
        document.getElementById('map' + storeInfo.id),
        {
          //   center: { lat: 24.9742352, lng: 121.0703047 },
          zoom: 14,
          mapTypeId: 'roadmap',
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }
      );
      const service = new google.maps.places.PlacesService(map);
      const request = {
        placeId: storeInfo.storePlaceId,
        fields: ['name', 'place_id', 'geometry', 'address_component'],
      };
      service.getDetails(request, place => {
        this.setMapCenter(
          map,
          JSON.parse(JSON.stringify(place.geometry.location))
        );
        new google.maps.Marker({
          position: JSON.parse(JSON.stringify(place.geometry.location)),
          map: map,
        });
      });
      this.stores = this.stores.map(store => {
        if (store.id === storeInfo.id) {
          store.hasDrawn = true;
        }
        return store;
      });
    },
    setMapCenter(map, pos) {
      map.setCenter({
        lat: pos.lat,
        lng: pos.lng,
      });
    },
    copy(ref, spanRef) {
      const copyText = this.$refs[ref][0];
      const span = this.$refs[spanRef][0];
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.value).then(() => {
        span.style.display = 'flex';
        setTimeout(() => {
          span.style.display = 'none';
        }, 1000);
      });
    },
    drop(store) {
      if (!store.hasDrawn) {
        this.drawMap(store);
      }
      const el = this.$refs[`store${store.id}`][0];
      if (el.classList.contains('dropdown')) {
        el.classList.remove('dropdown');
        el.classList.add('dropup');
      } else {
        el.classList.remove('dropup');
        el.classList.add('dropdown');
      }
    },
    parseOpeningHours(weekdayText) {
      if (!weekdayText) return [];
      return weekdayText.map(text => {
        let weekday = text.substring(0, text.indexOf(':')).trim();
        let openingHours = text.substring(text.indexOf(':') + 1).trim();
        switch (weekday) {
          case 'Monday':
            weekday = '星期一';
            break;
          case 'Tuesday':
            weekday = '星期二';
            break;
          case 'Wednesday':
            weekday = '星期三';
            break;
          case 'Thursday':
            weekday = '星期四';
            break;
          case 'Friday':
            weekday = '星期五';
            break;
          case 'Saturday':
            weekday = '星期六';
            break;
          case 'Sunday':
            weekday = '星期日';
            break;
        }
        if (openingHours === 'Closed') openingHours = '休息';
        return { weekday, openingHours };
      });
    },
  },
};
</script>
